
<template>
	<div>
		<PlatformHeader v-show="route != 'account'" />

		<slot id="nuxt--content" />

		<PlatformFooter v-show="route != 'account'" />

	</div>
</template>

<script lang="ts">


export default {
	data() {
		return {
			route: undefined
		}
	},
	watch: {
		$route: {
			// dont use immeadeate bc document not defined on ssr server
			handler(to, from) {
				this.checkURL()
			},
		},
	},
	mounted() {
		this.checkURL()

	},
	methods: {
		checkURL() {
			document.body.classList.forEach((c) => {
				if (c.startsWith("body--")) document.body.classList.remove(c)
			})
			let route = this.$route.fullPath.split('/')[1];
			route = route.split("?")[0];
			this.route = route
			document.body.classList.add("body--" + route)
		}
	},
}
</script>


<style lang="scss">
@import "assets/css/platform.scss";

#nuxt--content {
	min-height: 100vh;
}

.body--space,
.body--artist,
.body--,
.body--templates,
.body--toolkit {
	background: $main;
}

.body--templates {
	overflow-y: auto;
}

.body--account,
.body--accountNextcomm {

	header,
	footer,
	.subfooter {
		display: none;
	}
}
</style>
